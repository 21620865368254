import {
  Component,
  effect,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AutoSelectInputDirective } from '../../directives/auto-select-input.directive';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDivider } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { isEqual } from 'lodash';
import { AutoResizeInputDirective } from '../../directives/auto-resize-input.directive';
import { MarkdownService } from '../../helpers/markdown.service';
import { MarkdownComponent, MARKED_OPTIONS, provideMarkdown } from 'ngx-markdown';
import { JsonPipe } from '@angular/common';
import { marked } from 'marked';

@Component({
  standalone: true,
  selector: 'maporium-title-description',
  templateUrl: './title-description-input.component.html',
  styleUrls: ['./title-description-input.component.scss'],
  imports: [
    AutoSelectInputDirective,
    ReactiveFormsModule,
    MatDivider,
    MatInputModule,
    AutoResizeInputDirective,
    MarkdownComponent,
    JsonPipe
  ],
  providers: [
    provideMarkdown({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useValue: {
          gfm: true,
          breaks: true
        }
      }
    })
  ]
})
export class TitleDescriptionInputComponent implements OnChanges, OnInit, OnDestroy {
  @Input() fields: {
    title: string;
    description?: string;
  } | undefined;
  @Input() isEditing = false;
  @Input() isNew = false;
  @Input() noDescription = false;
  @Input() allowMultiLine = true;
  @Output() fieldsChange = new EventEmitter<{ title: string, description?: string, valid?: boolean }>();
  @ViewChild('titleInput') titleInput!: ElementRef;
  @ViewChild('textAreaElement') descriptionInput!: ElementRef;

  isMarkdownEnabled = false;

  formGroup = new FormGroup({
    title: new FormControl('', Validators.required),
    description: new FormControl('')
  });
  document = document;
  isUpdatingFromServer = false;

  constructor(private markdownService: MarkdownService) {
    effect(() => {
      this.isMarkdownEnabled = this.markdownService.markdownEnabledSignal;
    });
  }

  get description(): string {
    return MarkdownService.cleanUpMarkdown(marked(MarkdownService.convertToMarkdown(this.fields?.description || '')) as string);
  }

  ngOnInit() {
    if (this.fields && this.isEditing) {
      this.formGroup.patchValue(this.fields);
    }
    this.formGroup.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr))
      )
      .subscribe((value) => {
        // Only emit changes if not updating from server
        if (!this.isUpdatingFromServer) {
          const fields = this.formGroup.getRawValue();
          this.fieldsChange.emit({ ...fields, valid: this.formGroup.valid } as {
            title: string,
            description?: string,
            valid?: boolean
          });
        }
      });
  }


  ngOnDestroy(): void {
    const fields = this.formGroup.getRawValue();
    this.fieldsChange.emit({...fields, valid: this.formGroup.valid} as { title: string, description?: string, valid?: boolean});
  }

  ngOnChanges(c: any) {
    if (this.fields) {
      this.isUpdatingFromServer = true; // Set flag before updating
      setTimeout(() => {
        if (this.descriptionInput) {
          this.resizeDescription();
        }
      }, 100);
      this.formGroup.patchValue(this.fields);
      if (this.isNew) {
        this.selectNameInput();
      }
      this.isUpdatingFromServer = false; // Reset flag after update
    }
  }


  selectNameInput() {
    setTimeout(() => {
      this.titleInput?.nativeElement?.select();
      this.isNew = false;
    }, 200);
  }

  resizeDescription() {
    const textarea = this.descriptionInput.nativeElement;
    textarea.style.height = '20px';
    if (textarea.scrollHeight > textarea.clientHeight) {
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  }

  onEnter($event: any) {
    if(!this.allowMultiLine) {
      $event.preventDefault();
    }
  }
}
