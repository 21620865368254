import { Core, EdgeSingular } from 'cytoscape';
import { get, omit } from 'lodash';
import { GraphNodeLink } from '../../api/interfaces/graph-node.interface';

export function mapAnchorsToXYArray(anchors: number[]) {
  let result = [];

  // Iterate over the array in steps of 2 (for x and y coordinates)
  for (let i = 0; i < anchors.length; i += 2) {
    result.push({
      x: anchors[i],
      y: anchors[i + 1]
    });
  }

  return result;
}

export function getEqualDistributedAnchorPoints(edge: EdgeSingular) {

  // Get source and target node positions
  const sourcePos = edge.source().position();
  const targetPos = edge.target().position();

  // Calculate vector components for the direction from source to target
  const dx = targetPos.x - sourcePos.x;
  const dy = targetPos.y - sourcePos.y;

  // Calculate the two anchor points along the edge
  const anchorPointOneThirdFromSource = {
    x: sourcePos.x + dx / 3,
    y: sourcePos.y + dy / 3
  };

  const anchorPointOneThirdFromTarget = {
    x: targetPos.x - dx / 3,
    y: targetPos.y - dy / 3
  };

  return { source: anchorPointOneThirdFromSource, target: anchorPointOneThirdFromTarget };
}

export function callAddAnchor(edge: EdgeSingular, anchorType: 'bend' | 'control') {
  const addAnchorEvent = new CustomEvent('addAnchorEvent', {
    detail: {
      link: edge,
      anchorType,
      anchorPoints: getEqualDistributedAnchorPoints(edge)
    }
  });
  document.dispatchEvent(addAnchorEvent);
}

export function recreateLink(linkObject: GraphNodeLink, cy: Core): EdgeSingular {
  const edgeEditingProps = [
    'cyedgebendeditingDistances',
    'cyedgebendeditingWeights',
    'bendPointPositions',
    'controlPointPositions',
    'cyedgecontroleditingWeights',
    'cyedgecontroleditingDistances'
  ];

  const cleanedLinkData = omit(linkObject, edgeEditingProps);

  const linkId = get(linkObject, 'id');
  const sourceNodeId = get(linkObject, 'source');
  const targetNodeId = get(linkObject, 'target');

  cy.$('#' + linkId).remove();

  return cy.add({
    group: 'edges',
    data: {
      ...cleanedLinkData,
      source: sourceNodeId,
      target: targetNodeId
    }
  });
}
