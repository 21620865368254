import cytoscape from 'cytoscape';

export function generatePreviewCanvasData(data: { nodes: any[]; links: any[]; }, canvasContainer = 'cy-preview'): any {
  console.log(document.getElementById(canvasContainer));

  // Create a new Cytoscape instance for the preview
  const cy = cytoscape({
    container: document.getElementById(canvasContainer), // The DOM element for the preview canvas
    elements: [], // Initialize with an empty array
    style: [ // Default styles for nodes and edges
      {
        selector: 'node',
        style: {
          'background-color': '#666',
          'label': 'data(name)',
          'font-size': '10px',
          'font-family': 'Roboto, "Material Icons"',
          'text-halign': 'center',
          'text-valign': 'center',
          'text-margin-y': 1,
          'text-margin-x': 0,
          color: '#313131'
        }
      },
      {
        selector: 'edge',
        style: {
          'line-color': 'data(color)',
          'target-arrow-color': 'data(color)',
          'font-family': 'Roboto, "Material Icons"',
          'width': 2,
          'curve-style': (e) => {
            if (e.data('type') === 'STRAIGHT_TRIANGLE') {
              return 'straight-triangle';
            }
            return e.data('type')?.toLowerCase() || 'bezier';
          },
          'text-valign': 'center',
          'text-halign': 'center',
          'text-rotation': 'autorotate',
          'font-size': '8px',
          'text-margin-y': 6,
          'text-margin-x': 6,
          'color': 'data(color)',
          'label': 'data(name)' // Display the edge name as label
        }
      }
    ],
    layout: {
      name: 'grid',
      rows: 1
    }
  });

  const elements: any[] = [];

  // Generate nodes
  data.nodes.forEach(node => {
    const cyNode = {
      data: {
        id: node.id,
        name: node.name, // Node name will be displayed as label
        description: node.description,
        color: node.color,
        type: node.type,
        properties: node.properties,
        metadata: node.metadata
      },
      position: {
        x: node.x,
        y: node.y
      },
      style: {
        'background-color': node.color, // Use node's specified color
        'label': node.name, // Display the node name as label
        'font-size': node.properties.textFontSize || '10px', // Use the provided font size or default to 10px
        'color': node.properties.textFontColor || '#fff', // Use the provided text color or default to white
        'text-valign': 'center', // Align the label in the center vertically
        'text-halign': 'center' // Align the label in the center horizontally
      }
    };
    elements.push(cyNode);
  });

  // Generate links
  data.links.forEach(link => {
    console.log(link);
    const cyLink = {
      data: {
        id: link.id,
        source: link.from?.id || link.from,
        target: link.to?.id || link.to,
        name: link.name, // This is the link name to be displayed
        description: link.description,
        color: link.color,
        metadata: link.metadata,
        type: link.type,
        shape: link.shape,
        direction: link.direction
      }
    };
    elements.push(cyLink);
  });

  // Add elements to the Cytoscape instance
  cy.add(elements);

  // Optionally, layout the graph
  cy.layout({
    name: 'preset', // You can change the layout as needed
    animate: true
  });

  cy.fit(cy.elements()); // Fit the layout to the current elements

  return cy; // Return the Cytoscape instance for further manipulation if needed
}
