import { Injectable } from '@angular/core';
import { OpenaiResource } from '../resource/openai.resource';
import { map, Observable } from 'rxjs';
import { ExtendMetadataDto } from '../interfaces/extend-metadata.dto';
import { GeneralMapAssistantAnswer } from '../interfaces/general-map-assistant.answer';
import { SerializedMap } from '../interfaces/graph-node.interface';
import { ChatMessage, ThreadMessage } from '../interfaces/thread-messages.dto';
import { ExtendMetaResponse } from '../interfaces/extend-meta.response';

@Injectable({ providedIn: 'root' })
export class OpenaiService {
  constructor(private resource: OpenaiResource) {
  }

  extendMetadata(dto: ExtendMetadataDto, mapId: string): Observable<ExtendMetaResponse> {
    return this.resource.extendMetadata(dto, mapId);
  }

  generateAnswer(question: string, serializedMap: SerializedMap): Observable<GeneralMapAssistantAnswer> {

    const cleanedNodes = serializedMap.nodes.map(node => {
      const { links, children, ...cleanNode } = node; // Destructure to remove the links property
      return cleanNode; // Return node without links
    });
    const cleanedPayload = {
      ...serializedMap,
      nodes: cleanedNodes
    } as any;
    return this.resource.generateAnswer(question, cleanedPayload);
  }

  getMessages(threadId: string, limit: number = 20, after: string): Observable<any> {
    return this.resource.getMessages(threadId, limit, after);
  }

  getMessagesByMap(mapId: string, limit: number = 20, after?: string): Observable<ChatMessage[]> {
    return this.resource.getMessagesByMap(mapId, limit, after)
      .pipe(
        map((messages) => this.mapMessages(messages))
      );
  }

  private mapMessages(inputArray: ThreadMessage[]): ChatMessage[] {
    return inputArray.map((input) => {
      const firstContent = input.content[0];
      let text = '';
      let responseData: any;

      if (firstContent?.text?.value) {
        try {
          const parsedValue = JSON.parse(firstContent.text.value); // Parse as JSON to handle escaped characters
          console.log(parsedValue);
          if (parsedValue.responseType === 'data') {
            responseData = parsedValue.data;
            text = 'Preview';
          } else {
            text = typeof parsedValue === 'string' ? parsedValue : parsedValue.data || '';
          }
        } catch (error) {
          text = firstContent.text.value; // Fallback if parsing fails
        }
      }

      return {
        text: text.replace(/\\n/g, '<br>'),
        isSent: input.role === 'assistant',
        isCanvas: !!responseData,
        data: responseData,
        isUsers: input.role === 'user'
      };
    }).reverse();
  }

}
