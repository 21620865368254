import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Prompt } from '../interfaces/gpt-map.response';

@Injectable({
  providedIn: 'root'
})
/**
 * @deprecated
 */
export class GptResource {
  private baseUrl= environment.apiUrl + '/gpt';

  constructor(private http: HttpClient) {}

  generateMap(prompt: string, options: {generateColor: boolean}): Observable<Prompt> {
    return this.http.post<Prompt>(this.baseUrl + '/prompt', {prompt, generateColor: options.generateColor});
  }

  pullPormpt(id: string): Observable<Prompt> {
    return this.http.get<Prompt>(this.baseUrl + '/prompt/' + id);
  }
}
