import { Component, OnInit } from '@angular/core';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatToolbar } from '@angular/material/toolbar';
import { MatList, MatListItem } from '@angular/material/list';
import { MatCard, MatCardTitle } from '@angular/material/card';
import { MatFormField } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { OpenaiService } from '../../../api/services/openai.service';
import { MatProgressBar } from '@angular/material/progress-bar';
import { finalize } from 'rxjs';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { generatePreviewCanvasData } from '../../helpers/generate-preview-canvas';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CytoscapeService } from '../../../services/cy-services/cytoscape.service';
import { MapService } from '../../../api/services/map.service';
import { SerializedMap } from '../../../api/interfaces/graph-node.interface';
import { MatTooltip } from '@angular/material/tooltip';
import { MarkdownComponent } from 'ngx-markdown';
import { ChatMessage } from '../../../api/interfaces/thread-messages.dto';
import { UserService } from '../../../api/services/user.service';

@Component({
  selector: 'app-chat-dialog',
  standalone: true,
  styleUrls: ['./ai-chat-dialog.component.scss'],
  template: `
    <div class="w-100 d-flex justify-content-between align-items-center dialog-header">
      <h2 class="align-items-center d-flex m-0 p-0">
        <span class="material-symbols-rounded me-2">cognition</span>
        <span>Smap Assistant</span>
      </h2>
      <mat-icon class="material-symbols-rounded close-button" mat-dialog-close matTooltip="Close the panel">
        close
      </mat-icon>
    </div>

    <mat-dialog-content>
      <div #messageList class="message-list scroll-container" style="height: 100%; overflow-y: auto;">
        <mat-list class="message-list">
        <mat-list-item *ngFor="let message of messages; let i = index">
          @if(message.isCanvas) {
            <div class="code-container position-relative border rounded p-3 w-100">
              <!-- Language Header -->
              <div
                class="d-flex justify-content-between align-items-center bg-light border-bottom px-2 py-1 rounded-top">
                <span class="text-muted small">{{ 'Smap Assistant' || 'code' }}</span>
              </div>

              <!-- Floating Copy Button -->


              <!-- Code Content -->
              <pre class="mt-3">
                <div [id]="'cy-preview_' + i" class="cy-preview"></div>
              </pre>
              <button mat-button
                      (click)="add(i)"
                      matTooltip="Insert this data in current smap"
                      class="copy-btn position-absolute bottom-0 end-0 m-2 bg-dark text-white">
                <mat-icon>hub</mat-icon>
                Insert in Smap
              </button>
            </div>
          } @else {
            <div class="code-container position-relative border rounded p-3" [ngClass]="{'users': message.isUsers}">
              <!-- Language Header -->
              <div
                [ngClass]="{'users': message.isUsers, 'justify-content-end': message.isUsers, 'justify-content-between': !message.isUsers}"
                class="d-flex align-items-center bg-light border-bottom px-2 py-1 rounded-top">
                <span
                  class="text-muted small text-right">{{ (message.isUsers ? userName : 'Smap Assistant') || 'code' }}</span>
              </div>

              <!-- Code Content -->
              <pre class="mt-3"><code>{{ message.text }}</code></pre>
            </div>
          }
        </mat-list-item>
      </mat-list>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div class="d-flex justify-content-center align-items-center w-100 user-input-container">
        <mat-form-field class="d-flex w-100" style="margin-right: 10px;" appearance="outline">
          <textarea matInput [(ngModel)]="newMessage" (keyup.enter)="sendMessage()" [disabled]="isLoading"
                    cdkTextareaAutosize></textarea>
        </mat-form-field>

        <button mat-icon-button (click)="sendMessage()" [disabled]="isLoading" class="d-flex">
          @if (!isLoading) {
            <mat-icon>send</mat-icon>
          } @else {
            <mat-progress-spinner diameter="24" mode="indeterminate"></mat-progress-spinner>
          }
        </button>
      </div>
    </mat-dialog-actions>
    <div mat-dialog-actions class="d-flex justify-content-evenly">
      <button mat-button mat-dialog-close>Cancel</button>
    </div>
  `,
  imports: [
    MatDialogContent,
    MatToolbar,
    MatListItem,
    MatList,
    MatCard,
    MatFormField,
    CommonModule,
    FormsModule,
    MatInput,
    MatButton,
    MatIconButton,
    MatIcon,
    MatProgressBar,
    MatProgressSpinner,
    MatDialogActions,
    CdkTextareaAutosize,
    MatCardTitle,
    MatDialogClose,
    MatTooltip,
    MarkdownComponent
  ],
  styles: [`
    .sent-message {
      justify-content: flex-end;
    }

    .received-message {
      justify-content: flex-start;
    }

    mat-card {
      margin: 5px;
      padding: 10px;
    }
  `]
})
export class ChatDialogComponent implements OnInit {
  messages: ChatMessage[] = [];
  newMessage = '';
  isLoading = false;
  hasPreview = false;

  constructor(private dialogRef: MatDialogRef<ChatDialogComponent>,
              private cyService: CytoscapeService,
              private mapService: MapService,
              private userService: UserService,
              private generativeService: OpenaiService) {}

  get userName() {
    const user = this.userService.getCurrentUserFromStorage();
    return user.name + ' ' + user.lastName;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.generativeService.getMessagesByMap(this.mapService.getCurrentSelectedMapFromStore().id, 30)
        .subscribe((res) => {
          this.messages = res;
          this.messages.forEach((m) => {
            if (m.isCanvas) {
              setTimeout(() => {
                generatePreviewCanvasData(m.data, 'cy-preview_' + this.messages.indexOf(m));
              }, 100);
            }
          });
          this.scrollToBottom();
        });
    }, 300);

  }

  sendMessage(): void {
    if (this.newMessage.trim()) {
      this.isLoading = true;
      const cyJson = this.cyService.cy?.json() as any;
      if (!cyJson) {
        this.isLoading = false;
        return;
      }

      const cyEdges = cyJson.elements.edges;
      const cyNodes = cyJson.elements.nodes;

      const serialized: SerializedMap = {
        map: this.mapService.getCurrentSelectedMapFromStore(),
        nodes: cyNodes ? cyNodes.map((n: { data: any; }) => n.data) : [],
        links: cyEdges ? cyEdges.map((l: { data: any; }) => l.data) : []
      };

      this.generativeService.generateAnswer(this.newMessage, serialized)
        .pipe(
          finalize(() => this.isLoading = false)
        )
        .subscribe((response) => {
          const currentIndex = () => this.messages.length === 0 ? 0 : this.messages.length - 1;
          this.messages.push({ text: this.newMessage, isSent: true, isUsers: true, isCanvas: false});
          this.newMessage = '';
          if(response.responseType === 'data') {
            this.messages.push({
              text: 'Preview',
              isSent: false,
              isCanvas: true,
              isUsers: false,
              data: response.data
            });
            this.hasPreview = true;
            setTimeout(() => {
              generatePreviewCanvasData(this.messages[currentIndex()].data as {
                nodes: any[],
                links: any[]
              }, 'cy-preview_' + currentIndex());
            }, 300);
          } else {
            this.messages.push({ text: response.data as any, isSent: false, isCanvas: false, isUsers: false});
          }
          this.scrollToBottom();
        });
    }
  }

  add(index: number): void {
    this.dialogRef.close(this.messages[index].data);
  }

  scrollToBottom() {
    setTimeout(() => {
      const element = document.querySelector('.message-list') as HTMLElement;
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    }, 100);
  }
}
