import { EMPTY, finalize, Observable } from 'rxjs';
import { signal } from '@angular/core';

export const updateInProgress = signal<boolean>(false);

// Wrapper function to handle update state with an Observable-based operation
export function withUpdateInProgress<T>(operation: () => Observable<T>): Observable<T> {
  // If an update is already in progress, return an empty Observable to skip the request
  if (updateInProgress()) {
    return EMPTY;
  }

  // Set updateInProgress to true and execute the operation
  updateInProgress.set(true);

  return operation().pipe(
    finalize(() => {
      // Always reset updateInProgress to false after the Observable completes
      updateInProgress.set(false);
    })
  );
}
