import { isEqual, isEmpty, isArray, isObject } from 'lodash';

export function diff<T>(source: any, target: any): Partial<T> {
  const difference: Partial<T> = {};

  if (source == null || target == null) {
    return difference; // Return an empty difference if either source or target is null or undefined
  }

  Object.keys(target).forEach((key: string) => {
    const targetValue = target[key];
    const sourceValue = source.hasOwnProperty(key) ? source[key] : undefined;

    // Check if the current value is an object and perform a recursive diff if so
    if (isObject(targetValue) && !isArray(targetValue)) {
      const nestedDiff = diff(sourceValue, targetValue); // Recursive call to diff
      if (!isEmpty(nestedDiff)) {
        // @ts-ignore
        difference[key] = nestedDiff;
      }
    } else if (!isEqual(sourceValue, targetValue) && !(isEmpty(targetValue) && isArray(targetValue))) {
      // @ts-ignore
      difference[key] = targetValue;
    }
  });

  return difference;
}
