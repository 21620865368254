var reconnectionUtilities = {

  // creates and returns a dummy node which is connected to the disconnected edge
  disconnectEdge: function(edge, cy, position, disconnectedEnd) {

    var dummyNode = {
      data: {
        id: 'nwt_reconnectEdge_dummy',
        ports: []
      },
      renderedPosition: position
    };
    cy.add(dummyNode);

    var loc = (disconnectedEnd === 'source') ?
      { source: dummyNode.data.id } :
      { target: dummyNode.data.id };

    edge = edge.move(loc)[0];

    return {
      dummyNode: cy.nodes('#' + dummyNode.data.id)[0],
      edge: edge
    };
  },

  connectEdge: function(edge, node, location) {
    if (!edge.isEdge() || !node.isNode())
      return;

    var loc = {};
    if (location === 'source')
      loc.source = node.id();

    else if (location === 'target')
      loc.target = node.id();

    else
      return;

    return edge.move(loc)[0];
  },

  copyEdge: function(oldEdge, newEdge) {
    this.copyAnchors(oldEdge, newEdge);
    this.copyStyle(oldEdge, newEdge);
  },

  copyStyle: function(oldEdge, newEdge) {
    if (oldEdge && newEdge) {
      newEdge.data('line-color', oldEdge.data('line-color'));
      newEdge.data('width', oldEdge.data('width'));
      newEdge.data('cardinality', oldEdge.data('cardinality'));
    }
  },

  copyAnchors: function(oldEdge, newEdge) {
    if (oldEdge.hasClass('edgebendediting-hasbendpoints')) {
      var bpDistances = oldEdge.data('cyedgebendeditingDistances');
      var bpWeights = oldEdge.data('cyedgebendeditingWeights');

      newEdge.data('cyedgebendeditingDistances', bpDistances);
      newEdge.data('cyedgebendeditingWeights', bpWeights);
      newEdge.addClass('edgebendediting-hasbendpoints');
    } else if (oldEdge.hasClass('edgecontrolediting-hascontrolpoints')) {
      var bpDistances = oldEdge.data('cyedgecontroleditingDistances');
      var bpWeights = oldEdge.data('cyedgecontroleditingWeights');

      newEdge.data('cyedgecontroleditingDistances', bpDistances);
      newEdge.data('cyedgecontroleditingWeights', bpWeights);
      newEdge.addClass('edgecontrolediting-hascontrolpoints');
    }
    if (oldEdge.hasClass('edgebendediting-hasmultiplebendpoints')) {
      newEdge.addClass('edgebendediting-hasmultiplebendpoints');
    } else if (oldEdge.hasClass('edgecontrolediting-hasmultiplecontrolpoints')) {
      newEdge.addClass('edgecontrolediting-hasmultiplecontrolpoints');
    }
  }
};

module.exports = reconnectionUtilities;
