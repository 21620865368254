import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ExtendMetadataDto } from '../interfaces/extend-metadata.dto';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GeneralMapAssistantAnswer } from '../interfaces/general-map-assistant.answer';
import { SerializedMap } from '../interfaces/graph-node.interface';
import { ThreadMessage } from '../interfaces/thread-messages.dto';
import { ExtendMetaResponse } from '../interfaces/extend-meta.response';

@Injectable({ providedIn: 'root' })
export class OpenaiResource {
  private readonly baseUrl = environment.apiUrl;

  constructor(private readonly http: HttpClient) {
  }

  extendMetadata(dto: ExtendMetadataDto, mapId: string): Observable<ExtendMetaResponse> {
    return this.http.post<ExtendMetaResponse>(this.baseUrl + '/ai/extend-metadata', {
      dto,
      extendMetaOnly: false,
      mapId
    });
  }

  generateAnswer(question: string, serializedMap: SerializedMap): Observable<GeneralMapAssistantAnswer> {
    // Convert the cleaned payload into a Blob (JSON file)
    const blob = new Blob([JSON.stringify(serializedMap)], { type: 'application/json' });
    const formData = new FormData();
    formData.append('file', blob, 'mapData.json'); // Attach the file
    formData.append('question', question);

    return this.http.post<GeneralMapAssistantAnswer>(this.baseUrl + '/ai/map-assistant', formData);
  }

  getMessages(threadId: string, limit: number = 20, after: string): Observable<any> {
    return this.http.get(this.baseUrl + `/ai/message/${threadId}?limit=${limit}&after=${after}`);
  }

  getMessagesByMap(mapId: string, limit: number = 20, after?: string): Observable<ThreadMessage[]> {
    return this.http.get<ThreadMessage[]>(this.baseUrl + `/ai/message/map/${mapId}?limit=${limit}&after=${after}`);
  }
}
