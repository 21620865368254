import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MapStateDto } from '@maporium-workspace/shared';
import { HttpClient } from '@angular/common/http';


@Injectable({ providedIn: 'root' })
export class MapStateResource {
  private readonly baseUrl =  environment.apiUrl + '/state';

  constructor(private http: HttpClient) {
  }

  findAllForMap(mapId: string) {
    return this.http.get<MapStateDto[]>(`${this.baseUrl}/map/${mapId}`);
  }

  findOne(stateId: string) {
    return this.http.get<MapStateDto>(`${this.baseUrl}/${stateId}`);
  }

  findFeaturedForMap(mapId: string) {
    return this.http.get<MapStateDto[]>(`${this.baseUrl}/map/${mapId}/featured`);
  }

  createForMap(mapId: string, payload: MapStateDto) {
    return this.http.post<MapStateDto>(`${this.baseUrl}/map/${mapId}`, payload);
  }

  update(stateId: string, payload: MapStateDto) {
    return this.http.put<MapStateDto>(`${this.baseUrl}/${stateId}`, payload);
  }

  delete(stateId: string) {
    return this.http.delete(`${this.baseUrl}/${stateId}`);
  }
}
