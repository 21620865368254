<mat-toolbar color="accent" class="navbar navbar-expand-lg bg-body-tertiary position-absolute"
             *ngIf="isLoggedIn || readModeAppState">
  <div class="w-100 d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center justify-content-center">
      @if (isInIframe()) {
        <a class="navbar-brand ps-2 me-0"
           [class.pe-5]="!showVersionInfo"
           (click)="$event.preventDefault(); toggleBuildNumber()"
           href="#">
          <img src="assets/maporium-logotype-654x206.png" alt="Maporium">
        </a>
      } @else {
        <a class="navbar-brand ps-2 me-0"
           matTooltip="Toggle the version number display"
           [class.pe-5]="!showVersionInfo"
           href="#"
           (click)="$event.preventDefault(); toggleBuildNumber()">
          <img src="assets/maporium-logotype-654x206.png" alt="Maporium">
        </a>
      }
      <span *ngIf="showVersionInfo" class="ps-2 build-number">
              <span>{{ buildNumber.version }}</span><span *ngIf="showBuildInfo"> - {{ buildNumber.build }}</span>
            <br>
              <span *ngIf="servicePlan"> {{ servicePlan }}</span>
          </span>
    </div>

    @if (isIframe) {
      <span class="mx-2" style="font-weight: 400;">{{ currentMap?.name }}</span>
    }

    <!-- Map Group not in IFRAME-->
    @if (!readModeAppState) {
      <div class="d-flex align-items-center justify-content-center ">
        <button mat-icon-button aria-label="Manage your collection of smaps"
                matTooltip="Manage your collection of smaps: create, delete, import, export, and more"
                (click)="reloadMapList()"
                [matMenuTriggerFor]="mapMenu">
          <span class="material-symbols-rounded">map</span>
        </button>

        <mat-menu #mapMenu="matMenu" class="toolbar-menu">
          <button mat-menu-item (click)="createNewMap()">
            <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
            <span>Create Smap…</span>
          </button>
          <button mat-menu-item (click)="selectMap()">
            <mat-icon class="material-symbols-rounded">map</mat-icon>
            <span>Manage Smaps…</span>
          </button>
          @if (maps.length) {
            <mat-divider></mat-divider>
          }
          @for (map of maps; track map.id) {
            <button mat-menu-item
                    (click)="selectMap(map)">
              @if (map) {
                <span class="d-flex align-items-center map-menu-icons" [matTooltip]="map.description || ''">
                @if (map.isReadonly) {
                  <mat-icon class="material-symbols-rounded">visibility</mat-icon>
                } @else {
                  <ng-container *ngIf="map!.sharedWith && map!.sharedWith!.length > 0; else noShared">
                    <mat-icon class="material-symbols-rounded">group</mat-icon>
                  </ng-container>
                  <ng-template #noShared>
                    <mat-icon class="material-symbols-rounded">person</mat-icon>
                  </ng-template>
                }
                  {{ map.name }}
              </span>
              }
            </button>
          }
        </mat-menu>

        <button mat-icon-button
                [matTooltip]="getToolTipForMap()"
                [matMenuTriggerFor]="mapActionsMenu"
                *ngIf="currentMap">
          <span class="material-symbols-rounded">
            {{ currentMap.isReadonly ? 'visibility' : (currentMap.sharedWith?.length || 0) > 0 ? 'group' : 'person' }}
          </span>
        </button>

        <mat-menu #mapActionsMenu="matMenu" class="toolbar-menu">
          <button mat-menu-item [disabled]="currentMap?.isReadonly === true" (click)="editMap()">
            <mat-icon class="material-symbols-rounded">edit</mat-icon>
            <span>Update...</span>
          </button>
          <button mat-menu-item [disabled]="currentMap?.isReadonly === true" (click)="shareMap()">
            <mat-icon class="material-symbols-rounded">person_add</mat-icon>
            <span>Share...</span>
          </button>
          <button mat-menu-item
                  *appHasPackage="[packagenames.developer]"
                  [disabled]="currentMap?.isReadonly === true"
                  (click)="embedMap()">
            <mat-icon class="material-symbols-rounded">integration_instructions</mat-icon>
            <span>Embed...</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item [disabled]="currentMap?.isReadonly === true" (click)="duplicateMap()">
            <mat-icon class="material-symbols-rounded">file_copy</mat-icon>
            <span>Duplicate...</span>
          </button>
          <button mat-menu-item [disabled]="currentMap?.isReadonly === true" (click)="exportMap()">
            <mat-icon class="material-symbols-rounded">download</mat-icon>
            <span>Export...</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item *ngIf="!currentMap?.isReadonly; else readOnlyRemove" (click)="deleteMapConfirm()">
            <mat-icon class="material-symbols-rounded">delete</mat-icon>
            <span>Delete...</span>
          </button>
          <ng-template #readOnlyRemove>
            <button mat-menu-item (click)="removeMapFromSharedWithMe()">
              <mat-icon class="material-symbols-rounded">person_remove</mat-icon>
              <span>Remove...</span>
            </button>
          </ng-template>
        </mat-menu>
        @if (currentMap && currentMap.owner) {
          @if (currentMap!.owner.id === currentUser?.id) {
            <form [formGroup]="mapEditForm" id="mapNameForm">
              <app-text-input formControlName="name"
                              id="mapNameInput"
                              tabindex="0"
                              (keydown)="onMapNameKeydown($event)"
                              (hasBlured)="stopMapEdit($event)"
                              [value]="currentMap.name"
                              [autoSelect]="false"
                              [noTopMargin]="true"></app-text-input>
            </form>
          } @else {
            <span class="mx-2" style="font-weight: 400;">{{ currentMap.name || '' }}</span>
          }
        }

        @if (isIframe && mapStates.length > 0 || !isIframe) {
          <button mat-icon-button
                  [matMenuTriggerFor]="mapState"
                  matTooltip="Activate and manage smap states"
                  (click)="openStateMenu()"
                  *ngIf="currentMap">
            <span class="material-symbols-rounded">
              deployed_code
            </span>
          </button>
          <span style="font-weight: 400; font-size: 14px"
                *ngIf="currentNotDefaultMapState as state">{{ state }}</span>
        }
        <mat-menu #mapState="matMenu" class="toolbar-menu">
          <button mat-menu-item
                  *appHasPackage="[packagenames.publisher, packagenames.developer]"
                  (click)="createMapState()"
                  [disabled]="currentMap?.isReadonly">
            <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
            <span>Create State...</span>
          </button>
          <button mat-menu-item
                  (click)="openStateListSheet()"
                  *appHasPackage="[packagenames.publisher, packagenames.developer]"
                  [disabled]="currentMap?.isReadonly">
            <mat-icon class="material-symbols-rounded">deployed_code</mat-icon>
            <span>Manage States…</span>
          </button>
          <mat-divider *ngIf="mapStates.length > 0"></mat-divider>
          <button mat-menu-item
                  *ngIf="mapStates.length > 0"
                  (click)="selectMapState(defaultState)">
            <mat-icon *ngIf="currentNotDefaultMapStateId === null; else notSelected"
                      class="material-symbols-outlined">
              check
            </mat-icon>
            Base
          </button>
          <mat-divider *ngIf="mapStates.length > 0"></mat-divider>
          @for (mapState of mapStates; track mapState.id) {
            <button mat-menu-item
                    class="d-flex align-items-end"
                    [matTooltip]="mapState.description || mapState.name"
                    (click)="selectMapState(mapState)">
              <mat-icon *ngIf="mapState.id === currentNotDefaultMapStateId; else notSelected"
                        class="material-symbols-outlined">
                check
              </mat-icon>

              <span [matTooltip]="mapState.description || ''">{{ mapState.name }}</span>
            </button>
          }

          <ng-template #notSelected>
            <mat-icon class="empty-icon"></mat-icon>
          </ng-template>
        </mat-menu>

      </div>
    }

    <!--      State menu for iframe-->
    @if (isIframe && mapStates.length > 0) {
      <button mat-icon-button
              [matMenuTriggerFor]="mapState"
              matTooltip="Activate and manage smap states"
              (click)="openStateMenu()"
              *ngIf="currentMap">
            <span class="material-symbols-rounded">
              deployed_code
            </span>
      </button>
      <span style="font-weight: 400; font-size: 14px"
            *ngIf="currentNotDefaultMapState as state">{{ state }}</span>
    }
    <mat-menu #mapState="matMenu" class="toolbar-menu">
      <!--      <button (click)="selectMapState(defaultState)"-->
      <!--              *ngIf="mapStates.length > 0"-->
      <!--              mat-menu-item>-->
      <!--        <mat-icon *ngIf="currentNotDefaultMapStateId === null; else notSelected"-->
      <!--                  class="material-symbols-outlined">-->
      <!--          check-->
      <!--        </mat-icon>-->
      <!--        Base-->
      <!--      </button>-->
      <!--      <mat-divider *ngIf="mapStates.length > 0"></mat-divider>-->
      @for (mapState of mapStates; track mapState.id) {
        <button mat-menu-item
                class="d-flex align-items-end"
                [matTooltip]="mapState.description || mapState.name"
                (click)="selectMapState(mapState)">
          <mat-icon *ngIf="mapState.id === currentNotDefaultMapStateId; else notSelected"
                    class="material-symbols-outlined">
            check
          </mat-icon>

          <span [matTooltip]="mapState.description || ''">{{ mapState.name }}</span>
        </button>
      }

      <ng-template #notSelected>
        <mat-icon class="empty-icon"></mat-icon>
      </ng-template>
    </mat-menu>

    <!-- Tool Group-->
    <div class="d-flex align-items-center justify-content-center">
      <button (click)="centerCanvas()"
              *ngIf="currentMap"
              aria-label="Center Canvas"
              mat-icon-button
              matTooltip="See the current smap in its entirety">
        <span class="material-symbols-rounded">home</span>
      </button>
      @if (isIframe && tours.length > 0 || !isIframe) {
        <button mat-icon-button
                aria-label="Saved Views"
                matTooltip="Explore and manage smap scenes"
                (click)="loadToursAndViews()"
                *ngIf="currentMap"
                [matMenuTriggerFor]="viewsMenu">
          <span class="material-symbols-rounded">location_on</span>
        </button>
      }
      <mat-menu #viewsMenu="matMenu" class="toolbar-menu">
        @if (!readModeAppState) {
          <button mat-menu-item *appHasModule="MODULE_NAMES.VIEW_MANAGEMENT"
                  (click)="createView()"
                  [disabled]="currentMap?.isReadonly">
            <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
            <span>Create Scene…</span>
          </button>
          <button mat-menu-item
                  [disabled]="currentMap?.isReadonly"
                  *appHasModule="MODULE_NAMES.VIEW_MANAGEMENT"
                  (click)="selectView()">
            <mat-icon class="material-symbols-rounded">location_on</mat-icon>
            <span>Manage Scenes…</span>
          </button>
        }
        <mat-divider *ngIf="views.length && !readModeAppState"></mat-divider>

        <ng-container *ngIf="!canManageViews && views.length === 0">
          <button disabled mat-menu-item>No Saved Scenes</button>
        </ng-container>
        <ng-container *ngIf="views.length > 0">
          @for (view of views; track view.id) {
            <button mat-menu-item
                    (click)="startView(view)"
                    [matTooltip]="view.description ? view.description : view.name">{{ view.name }}
            </button>
          }
        </ng-container>
      </mat-menu>


      @if (isIframe && tours.length > 0 || !isIframe) {

        <button mat-icon-button
                aria-label="Tours"
                matTooltip="Explore and manage smap stories"
                (click)="loadToursAndViews()"
                *ngIf="currentMap"
                [matMenuTriggerFor]="tourMenu">
          <span class="material-symbols-rounded">play_circle</span>
        </button>
      }
      <mat-menu #tourMenu="matMenu" class="toolbar-menu">
        @if (!readModeAppState) {
          <ng-container *appHasModule="MODULE_NAMES.TOUR_MANAGEMENT">
            <button mat-menu-item (click)="createTour()" [disabled]="currentMap?.isReadonly">
              <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
              <span>Create Story…</span>
            </button>
            <button mat-menu-item (click)="selectTour()" [disabled]="currentMap?.isReadonly">
              <mat-icon class="material-symbols-rounded">play_circle</mat-icon>
              <span>Manage Stories…</span>
            </button>
            <mat-divider *ngIf="tours.length && !readModeAppState"></mat-divider>
          </ng-container>
        }
        <ng-container *ngIf="!canManageTours && tours.length === 0">
          <button disabled mat-menu-item>No Saved Stories</button>
        </ng-container>
        @for (tour of tours; track tour.id) {
          <button mat-menu-item
                  (click)="startTour(tour)"
                  [matTooltip]="tour.description ? tour.description : tour.name">{{ tour.name }}
          </button>
        }
      </mat-menu>
    </div>

    @if (!readModeAppState) {
      <!-- Edit Group-->
      <div class="d-flex align-items-center justify-content-center">
        <button mat-icon-button
                aria-label="Create Node"
                matTooltip="Create a new node (or use the contextual menu in the smap)"
                *ngIf="currentMap"
                [disabled]="currentMap.isReadonly === true"
                (click)="createDefaultNode()">
          <span class="material-symbols-rounded">add</span>
        </button>
        <button mat-icon-button
                *appHasPackage="[packagenames.developer]"
                aria-label="Use the smap assistant to get answers or generate smap elements to insert"
                [disabled]="currentMap?.isReadonly === true"
                matTooltip="Use the smap assistant to get answers or generate smap elements to insert"
                (click)="openGptDialog()">
          <span class="material-symbols-rounded">cognition</span>
        </button>
        <button mat-icon-button
                *ngIf="showGptSave"
                aria-label="Cancel Gpt Map Generator"
                [disabled]="currentMap?.isReadonly === true"
                matTooltip="Cancel Gpt Map Generator"
                (click)="cancelGptGeneratedMap()">
          <mat-icon>cancel</mat-icon>
        </button>
        <button mat-icon-button
                *ngIf="showGptSave"
                aria-label="Add to Map"
                [disabled]="currentMap?.isReadonly === true"
                matTooltip="Add to Map"
                (click)="handleGpt('map')">
          <mat-icon>done</mat-icon>
        </button>
      </div>
    }


    <!-- Find Group-->
    <div class="d-flex align-items-center justify-content-center">
      <button [matMenuTriggerFor]="linkViewModeMenu"
              aria-label="Toggle Link Visibility"
              mat-icon-button
              matTooltip="Configure what links to display">
        <span class="material-symbols-rounded">network_node</span>
      </button>
      <mat-menu #linkViewModeMenu="matMenu" class="toolbar-menu">
        <button (click)="toggleEdges(0)"
                mat-menu-item>
          <mat-icon *ngIf="currentEdgeView === 0">check</mat-icon>
          <mat-icon *ngIf="currentEdgeView !== 0"></mat-icon>
          <span>All Links</span>
        </button>
        <button (click)="toggleEdges(2)"
                mat-menu-item>
          <mat-icon *ngIf="currentEdgeView !== 2"></mat-icon>
          <mat-icon *ngIf="currentEdgeView === 2">check</mat-icon>
          <span>Selection Links</span>
        </button>
        <button (click)="toggleEdges(1)"
                mat-menu-item>
          <mat-icon *ngIf="currentEdgeView !== 1"></mat-icon>
          <mat-icon *ngIf="currentEdgeView === 1">check</mat-icon>
          <span>No Links</span>
        </button>
      </mat-menu>
    </div>

    <!-- Support Group-->
    <div class="d-flex align-items-center justify-content-center">
      <button [mat-menu-trigger-for]="helpMenu" aria-label="Help"
              mat-icon-button
              matTooltip="Find the help you need">
        <span class="material-symbols-rounded">help</span>
      </button>
      <mat-menu #helpMenu="matMenu" class="toolbar-menu">
        @if (!isIframe) {
          <button mat-menu-item (click)="openReleaseNotes()">View Release Notes</button>
        }
        <button (click)="sendMail()" mat-menu-item>Contact Support</button>
        <button (click)="visitWebsite()" mat-menu-item>Visit Website</button>
      </mat-menu>
      @if (!isIframe) {
        <button mat-icon-button aria-label="Settings"
                matTooltip="Configure the application settings" (click)="openSettings()">
          <span class="material-symbols-rounded">settings</span>
        </button>
      }
      @if (!readModeAppState) {
        <maporium-edit-mode-button *ngIf="currentMap" [readonly]="currentMap.isReadonly"></maporium-edit-mode-button>
      }
      <button (click)="toggleSidebar()"
              [matTooltip]="isSidebarOpen ? 'Hide the information sidebar' : 'Show the information sidebar'"
              mat-icon-button>
            <span class="material-symbols-rounded"
                  *ngIf="!isSidebarOpen; else sidebarOpenedIcon">right_panel_open</span>
        <ng-template #sidebarOpenedIcon>
          <span class="material-symbols-rounded">right_panel_close</span>
        </ng-template>
      </button>
    </div>
  </div>

  <mat-progress-bar *ngIf="isBlocking" color="primary" mode="indeterminate"></mat-progress-bar>
</mat-toolbar>
