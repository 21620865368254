import {
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GraphMap } from '../api/interfaces/graph-node.interface';
import { MapService } from '../api/services/map.service';
import { UserHarmonicsComponent } from '../shared/user-harmonic/user-harmonics.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs';
import { UserService } from '../api/services/user.service';
import { HasModuleDirective } from '../packages/has-module.directive';
import { MODULE_NAMES } from '@maporium-workspace/shared';
import { SettingsService } from '../api/services/setting.service';
import { SidebarService } from '../sidebar-form/sidebar.service';
import { AutoSelectInputDirective } from '../shared/directives/auto-select-input.directive';
import { MetaDataControlComponent } from '../shared/forms/key-value-table/meta-data-control.component';
import { isEqual, orderBy, sortBy } from 'lodash';
import { DatatifyDirective } from '../shared/directives/datatify.directive';
import { OrderByPipe } from '../shared/pipes/orderBy.pipe';
import { MetadataService } from '../api/services/metadata.service';
import { MetaDataViewerComponent } from '../shared/forms/meta-data-viewer/meta-data-viewer.componet';
import {
  TitleDescriptionInputComponent
} from '../shared/forms/title-description-input/title-description-input.component';
import {
  MaporiumAccordionItemComponent
} from '../shared/components/maporium-accordion/maporium-accordion-item.component';
import { CdkAccordion } from '@angular/cdk/accordion';
import { CommentsComponent } from '../shared/components/comments/comments.component';
import { AppWideStateService } from '../services/app-state/app-wide-state.service';
import { OpenaiService } from '../api/services/openai.service';

@Component({
  selector: 'maporium-map-info-section',
  template: `
    @if (!hasNoMap) {
      <div class="main-content maporium-map-info-section">
        <maporium-title-description [fields]="nameDescriptionFields"
                                    (fieldsChange)="onNameDescriptionChange($event)"
                                    [allowMultiLine]="false"
                                    [isEditing]="isEditable && !readModeAppState"></maporium-title-description>

        <div class="mt-1">
          <cdk-accordion [multi]="true" class="accordion">
            <maporium-accordion-item [title]="'Properties'"
                                     [sections]="metadata?.length || 0">
              <ng-container *ngIf="!isEditMode || readonly || readModeAppState; else mapName">
                <ng-container *ngIf="metadata">
                  <maporium-meta-viewer [metadata]="metadata"></maporium-meta-viewer>
                </ng-container>
              </ng-container>
            </maporium-accordion-item>
            @if (selectedMap?.id && !readModeAppState) {
              <maporium-accordion-item [title]="'Comments'" [sections]="commentsCount">
                <maporium-comments [entityId]="selectedMap!.id"
                                   (commentCount)="commentsCount = $event"
                                   [entityType]="'map'"
                                   [isEditing]="true"></maporium-comments>
              </maporium-accordion-item>
            }
          </cdk-accordion>
        </div>


        <ng-template #mapName>
          <form [formGroup]="mapForm" autocomplete="off" class="mb-3">
            <maporium-meta-data-control formControlName="metadata"
                                        (extendMeta)="onMetaExtend()"
                                        (addMeta)="onAddMeta($event)"></maporium-meta-data-control>
          </form>
        </ng-template>
      </div>
    }
  `,
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatTooltipModule,
    ReactiveFormsModule,
    UserHarmonicsComponent,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    HasModuleDirective,
    AutoSelectInputDirective,
    MetaDataControlComponent,
    DatatifyDirective,
    OrderByPipe,
    MetaDataViewerComponent,
    TitleDescriptionInputComponent,
    MaporiumAccordionItemComponent,
    CdkAccordion,
    CommentsComponent
  ],
  styleUrls: ['./map-info-section.component.scss']
})

export class MapInfoSectionComponent implements OnInit {
  MODULE_NAMES = MODULE_NAMES;

  public isEditMode = false;
  public mapForm: FormGroup;
  public selectedMap: GraphMap | undefined;
  public nameDescriptionFields: {
    title: string;
    description?: string;
  } = { title: '', description: '' };
  public isValid = true;
  readModeAppState = false;

  @ViewChild('mapNameInput') mapNameInput!: ElementRef;
  @Input() readonly = false;
  @Input() hasNoMap = false;
  @Output() mapMetaChanged = new EventEmitter<GraphMap>;

  private newMetaAdded = false;
  private debounceTime = 200;
  commentsCount = 0;

  constructor(private mapService: MapService,
              private fb: FormBuilder,
              private settingsService: SettingsService,
              private cdr: ChangeDetectorRef,
              private metadataService: MetadataService,
              private openaiService: OpenaiService,
              private sidebarService: SidebarService,
              private appWideStateService: AppWideStateService,
              private userService: UserService) {
    this.mapForm = this.fb.group({
      metadata: new FormControl([])
    });

    effect(() => {
      this.readModeAppState = this.appWideStateService.readMode();
    });

    effect(() => {
      this.isEditMode = this.sidebarService.getEditModeSignal();
      this.checkMapIsEditable();
    }, { allowSignalWrites: true });

    effect(() => {
      const map = this.mapService.selectedMapSignal;
      if (map) {
        //@ts-ignore
        this.selectedMap = map?.name ? map : map.map as GraphMap;
        if (this.selectedMap?.metadata !== undefined) {
          this.nameDescriptionFields = {
            title: this.selectedMap?.name || '',
            description: this.selectedMap?.description || ''
          };

          if (!isEqual(this.selectedMap?.metadata, this.mapForm.value.metadata?.pairs) && !this.newMetaAdded) {
            this.mapForm.setValue({
              metadata: this.selectedMap?.metadata ? orderBy(this.selectedMap?.metadata, 'order', ['asc']) : []
            });
          }
        }

        this.checkMapIsEditable();
      }
    }, { allowSignalWrites: true });
  }

  get isEditable() {
    return this.isEditMode && this.isMapOwner && !this.isReadOnly;
  }

  get isMapOwner() {
    return this.selectedMap?.owner?.id === this.userService.getCurrentUserFromStorage()?.id;
  }

  get isReadOnly() {
    return this.selectedMap?.isReadonly;
  }

  get metadata() {
    if (!this.selectedMap) return [];
    return orderBy(this.selectedMap?.metadata, 'order', 'asc');
  }

  ngOnInit() {
    const currentSelected = this.mapService.getCurrentSelectedMapFromStore();
    this.nameDescriptionFields = {
      title: currentSelected?.name || ' ',
      description: currentSelected?.description || ' '
    };
    this.mapForm.patchValue({
      metadata: currentSelected?.metadata ? orderBy(currentSelected.metadata, 'order', ['asc']) : []
    });
    this.mapForm.valueChanges
      .pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        filter(() => !this.newMetaAdded)
      )
      .subscribe((value) => {
        if (!this.selectedMap) return;
        if (!this.mapForm.valid) return;
        if (this.newMetaAdded) return;
        const hastMetadataChanged = !isEqual(sortBy(this.selectedMap.metadata, 'id'), sortBy(value.metadata.pairs, 'id')) && value.metadata?.pairs !== undefined;
        const hasChanged = this.selectedMap.name !== this.nameDescriptionFields.title || this.selectedMap.description !== this.nameDescriptionFields.description;
        if (hastMetadataChanged) {
          this.selectedMap.metadata = value.metadata?.pairs;
          this.cdr.detectChanges();
        }

        if (this.isEditable && (hasChanged || hastMetadataChanged)) {
          this.mapService.update(this.selectedMap)
            .subscribe((map) => {
              if (!hastMetadataChanged) {
                this.mapService.setCurrentSelectedMapToStore(map);
              }
              this.mapMetaChanged.emit(map);
            });
        }
      });

    this.mapService.mapNameChangedSubject.subscribe((name) => {
      this.selectedMap = this.mapService.getCurrentSelectedMapFromStore();
      this.nameDescriptionFields.title = name;
      this.nameDescriptionFields.description = this.selectedMap?.description || '';

      this.mapForm.setValue({
        metadata: orderBy(this.selectedMap.metadata, 'order', ['asc'])
      });
    });
  }


  onAddMeta(pair: FormGroup<{ id: FormControl, value: FormControl, key: FormControl }>) {
    if (!this.selectedMap) return;
    this.newMetaAdded = true;
    this.metadataService.createOneForMap(this.selectedMap.id, pair.value as any)
      .subscribe((meta) => {
        pair.patchValue(meta);
        this.newMetaAdded = false;
      });
  }

  onNameDescriptionChange(fields: { title: string, description?: string, valid?: boolean }) {
    if (!this.isEditable) return;
    if (!fields.valid) {
      this.isValid = false;
      return;
    }
    this.nameDescriptionFields = fields;
    const hasChanged = this.selectedMap?.name !== fields.title || this.selectedMap?.description !== fields.description;
    if (!hasChanged) return;
    if (!this.selectedMap) return;
    this.selectedMap.name = fields.title;
    this.selectedMap.description = fields.description;
    this.mapService.update(this.selectedMap).subscribe((map) => {
      this.mapService.setCurrentSelectedMapToStore(map);
      if (hasChanged) {
        this.mapService.mapNameChangedSubject.next(map.name);
      }
    });
  }


  /**
   * @deprecated
   */
  private loadMap() {
    const mapFromStore = this.mapService.getCurrentSelectedMapFromStore();
    if (!mapFromStore?.id) {
      return;
    }
    this.mapService.findByIdWithRelation(mapFromStore.id).subscribe((map) => {
      this.selectedMap = map;

      this.mapForm.setValue({
        metadata: orderBy(map.metadata, 'order', ['asc'])
      });
      this.nameDescriptionFields = {
        title: map.name,
        description: map.description
      };
      this.checkMapIsEditable();
    });
  }

  private checkMapIsEditable() {
    if (!this.selectedMap) return;
    if (this.isEditable) {
      this.mapForm.enable();
    } else {
      this.mapForm.disable();
    }
  }

  onMetaExtend() {
    const currentMeta = this.selectedMap?.metadata || [];
    if (currentMeta.length > 0) {
      this.openaiService.extendMetadata({
        title: this.selectedMap?.name || '',
        description: this.selectedMap?.description || '',
        metadata: currentMeta
      }, this.selectedMap!.id)
        .subscribe((result) => {
          this.selectedMap!.metadata = result.metadata;
          this.selectedMap!.description = result.description;
          this.mapForm.setValue({
            metadata: result,
            description: result.description
          });
        });
    }

  }
}
