import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ColorSketchModule } from 'ngx-color/sketch';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MapService } from '../api/services/map.service';
import { UserService } from '../api/services/user.service';
import { ViewService } from '../api/services/view.service';
import { CytoscapeService } from '../services/cy-services/cytoscape.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SavedView } from '../api/interfaces/saved-view';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TextInputComponent } from '../shared/forms/text-input/text-input.component';
import { SlideToggleInputComponent } from '../shared/forms/slide-select/slide-toggle-input.component';
import { TextAreaComponent } from '../shared/forms/text-area/text-area-input.component';
import { SettingsService } from '../api/services/setting.service';
import { HasModuleDirective } from '../packages/has-module.directive';
import { MODULE_NAMES } from '@maporium-workspace/shared';
import { SidebarState } from '../../../../../libs/shared/src/lib/consts/sidebar.state';
import { MapStateService } from '../api/services/map-state.service';
import { DashboardComponent } from '../dashboard/dashboard.component';

@Component({
  selector: 'maporium-new-map-dialog',
  template: `
    <div class="w-100 d-flex justify-content-between align-items-center dialog-header">
      <h2 class="align-items-center d-flex m-0 p-0"><span class="material-symbols-rounded me-2">{{data? 'edit' : 'add_circle'}}</span>
        {{data ? 'Update Scene' : 'Create Scene'}}
      </h2>
      <mat-icon class="material-symbols-rounded close-button"  mat-dialog-close matTooltip="Close the panel">
        close
      </mat-icon>
    </div>
    <div mat-dialog-content>
      <form [formGroup]="myForm">
        <app-text-input [label]="'Title'" formControlName="name" [autoFocusAndSelect]="true"></app-text-input>
        <app-text-area [label]="'Description'" formControlName="description"></app-text-area>
        <ng-container *ngIf="!data">
          <app-slide-toggle-input [label]="'deployed_code'"
                                  [tooltip]="'Remember active state'"
                                  [isIcon]="true"
                                  *ngIf="hasStates"
                                  matTooltip="Remember what state is currently active in the smap"
                                  formControlName="includeCurrentState"></app-slide-toggle-input>
          <app-slide-toggle-input [label]="'select'"
                                  [tooltip]="'Remember current selection'"
                                  [isIcon]="true"
                                  matTooltip="Remember what nodes and links are selected or unselected in the smap"
                                  formControlName="includeSelected"></app-slide-toggle-input>
          <app-slide-toggle-input [label]="'network_node'"
                                  matTooltip="Remember what links are displayed based on the corresponding toolbar control"
                                  [tooltip]="'Remember current link display mode'"
                                  [isIcon]="true"
                                  formControlName="includeLinkState"></app-slide-toggle-input>
          <app-slide-toggle-input [label]="'dock_to_left'"
                                  [isIcon]="true"
                                  matTooltip="Remember the visibility and width of the sidebar"
                                  [tooltip]="'Remember sidebar configuration'"
                                  formControlName="includeSidebar"></app-slide-toggle-input>

        </ng-container>
        <p class="mt-5"></p>
        <app-slide-toggle-input [label]="'visibility_off'"
                                [isIcon]="true"
                                matTooltip="Prevent viewers of this smap from seeing this scene"
                                [tooltip]="'Hide from smap viewers'"
                                formControlName="excludeFromReadOnly"></app-slide-toggle-input>
      </form>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-evenly">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button
              (click)="data ? edit() : create()"
              [disabled]="!myForm.valid"
              color="accent"
              cdkFocusInitial>{{data ? 'Update' : 'Create'}}</button>
    </div>
  `,
  imports: [
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    ColorSketchModule,
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    MatTooltipModule,
    TextInputComponent,
    SlideToggleInputComponent,
    TextAreaComponent,
    HasModuleDirective
  ],
  standalone: true
})
/**
 * Dialog for new "Scene" ex "Saved View"
 */
export class CreateNewViewDialogComponent implements OnInit{
  myForm: FormGroup;
  MODULE_NAMES = MODULE_NAMES;
  hasStates = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SavedView,
    private matDialogRef: MatDialogRef<CreateNewViewDialogComponent>,
              private userService: UserService,
              private cyService: CytoscapeService,
              private mapService: MapService,
              private stateService: MapStateService,
              private settingsService: SettingsService,
              private viewService: ViewService) {
    this.myForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      includeSelected: new FormControl(true),
      includeSidebar: new FormControl(true),
      includeLinkState: new FormControl(true),
      includeCurrentFilter: new FormControl(false),
      includeCurrentState: new FormControl(true),
      excludeFromReadOnly: new FormControl(false),
    });
  }

  ngOnInit(): void {
    if(this.data) {
      this.myForm.get('includeLinkState')?.setValue(this.data.linkState !== null);
      this.myForm.get('includeSidebar')?.setValue(this.data.sidebarState !== null);
      this.myForm.get('includeCurrentState')?.setValue(this.data.includeCurrentState !== null);
      this.myForm.patchValue(this.data);
    }

    setTimeout(() => {
      this.stateService.findAllForMap(this.mapService.getCurrentSelectedMapFromStore()?.id as string).subscribe((states) => {
        this.hasStates = states.length > 0;
      });
    },200)
  }

  create() {
    if(this.myForm.valid) {
      const cy = this.cyService.cy;
      const sidebarWidth = this.settingsService.getLocalStorageSettings().sidebarWidth;
      if (!cy) {
        return;
      }
      const state = this.stateService.getCurrentLocalState();
      const payload: SavedView = {
        name: this.myForm.value.name,
        pan: cy.pan(),
        zoom: cy.zoom(),
        excludeFromReadOnly: this.myForm.value.excludeFromReadOnly,
        includeCurrentState: this.myForm.value.includeCurrentState,
        savedStateId: state?.id,
        description: this.myForm.value.description,
        map: this.mapService.getCurrentSelectedMapFromStore(),
        linkState: this.myForm.value.includeLinkState ? this.settingsService.linkViewMode : null,
        sidebarState: this.myForm.value.includeSidebar ? this.settingsService.getLocalStorageSettings().isSidebarOpen ? SidebarState.OPEN : SidebarState.CLOSED : null,
        selected: this.myForm.value.includeSelected ? cy.$(':selected').map(node => node.data('id')) : null,
        collapsedNodes: this.cyService.cy?.nodes().filter(node => node.data('collapsed')).map(node => node.data('id')) as string[],
        metadata: {
          sidebarWidth: sidebarWidth as number,
          focusedElementId: DashboardComponent.tappedNode?.id || DashboardComponent.tappedLink?.id || undefined
        }
      }
      this.viewService.create(payload).subscribe((res) => this.matDialogRef.close(res));
    }
  }

  edit() {
    if(this.myForm.valid) {
      const payload = {
        ...this.data,
        name: this.myForm.value.name,
        description: this.myForm.value.description,
        excludeFromReadOnly: this.myForm.value.excludeFromReadOnly,
      }
      this.viewService.update(payload)
        .subscribe((res) => this.matDialogRef.close(res));
    }
  }

}
