import { Injectable, signal } from '@angular/core';
import { MapStateResource } from '../resource/map-state.resource';
import { MapStateDto } from '@maporium-workspace/shared';
import { BehaviorSubject } from 'rxjs';
import { MapStateLocalStorage } from '../interfaces/map-state.local-storage';
import { MapService } from './map.service';
import { CytoscapeService } from '../../services/cy-services/cytoscape.service';

export const MAP_STATE_KEY = 'mapState';
export const DEFAULT_MAP_STATE: MapStateDto = {
  name: 'Base',
  id: undefined,
};

@Injectable({ providedIn: 'root' })
export class MapStateService {

  /**
   * @deprecated use signal
   */
  public changingStateSubject = new BehaviorSubject<boolean>(false);
  private readonly selectedStateSignal = signal<MapStateDto | null>(null);
  private readonly sceneChangeProgressSignal = signal<boolean>(false);

  constructor(private mapStateResource: MapStateResource,
              private mapService: MapService) {
    try {
      const state = JSON.parse(localStorage.getItem(MAP_STATE_KEY) as string) as MapStateLocalStorage;
      const currentMapId = this.mapService.getCurrentSelectedMapFromStore()?.id;
      this.selectedStateSignal.set(state[currentMapId]);
    } catch (e) {
    }
  }

  isDefaultState(): boolean {
    const currentSelectedState = this.getLocalState();
    const currentMapId = this.mapService.getCurrentSelectedMapFromStore()?.id;
    return currentSelectedState ? currentSelectedState[currentMapId]?.id === undefined : true;
  }

  findAllForMap(mapId: string) {
    return this.mapStateResource.findAllForMap(mapId);
  }

  findOne(stateId: string) {
    return this.mapStateResource.findOne(stateId);
  }

  findFeaturedForMap(mapId: string) {
    return this.mapStateResource.findFeaturedForMap(mapId);
  }

  createForMap(mapId: string, payload: MapStateDto) {
    return this.mapStateResource.createForMap(mapId, payload);
  }

  update(stateId: string, payload: MapStateDto) {
    return this.mapStateResource.update(stateId, payload);
  }

  delete(stateId: string) {
    return this.mapStateResource.delete(stateId);
  }

  setSceneChangeProgress(progress: boolean) {
    this.sceneChangeProgressSignal.set(progress);
  }

  getSceneChangeProgressSignal() {
    return this.sceneChangeProgressSignal();
  }

  selectState(state: MapStateDto | null) {
    if (state === null) {
      localStorage.setItem(MAP_STATE_KEY, JSON.stringify(DEFAULT_MAP_STATE));
      this.selectedStateSignal.set(null);
      return;
    }
    try {
      const currentMapId = this.mapService.getCurrentSelectedMapFromStore()?.id;
      const currentLocalState = JSON.parse(localStorage.getItem(MAP_STATE_KEY) as string) as MapStateLocalStorage;
      if (currentLocalState && currentLocalState[currentMapId]?.id === state?.id) {
        return;
      }
      const newState: MapStateLocalStorage = {
        ...currentLocalState,
        [currentMapId]: state
      };

      localStorage.setItem(MAP_STATE_KEY, JSON.stringify(newState));
      this.selectedStateSignal.set(state);
    } catch (e) {
    }
    CytoscapeService.menuChangedSubject.next();
  }

  public getStateSignal() {
    return this.selectedStateSignal();
  }


  getLocalState(): MapStateLocalStorage {
    try {
      return JSON.parse(localStorage.getItem(MAP_STATE_KEY) as string) as MapStateLocalStorage;
    } catch (e) {
      return {};
    }
  }

  getCurrentLocalState(): MapStateDto | null {
    try {
      const currentMapId = this.mapService.getCurrentSelectedMapFromStore()?.id;
      const currentLocalState = this.getLocalState();
      return currentLocalState[currentMapId];
    } catch (e) {
      return DEFAULT_MAP_STATE;
    }
  }

  updateCurrentLocalState(payload: MapStateDto) {
    try {
      const currentMapId = this.mapService.getCurrentSelectedMapFromStore()?.id;
      const currentLocalState = this.getLocalState();
      const newState: MapStateLocalStorage = {
        ...currentLocalState,
        [currentMapId]: payload
      };
      localStorage.setItem(MAP_STATE_KEY, JSON.stringify(newState));
    } catch (e) {
    }
  }

  public async getCtxMenuItems() {
    const mapId = this.mapService.getCurrentSelectedMapFromStore().id;
    if (!mapId) {
      return null;
    }

    // TODO ADD MODULE
    // if(!this.packageService.hasModule(MODULE_NAMES.TOUR_USAGE)) {
    //   return null;
    // }

    const states = await this.findAllForMap(mapId).toPromise();

    if (!states || states.length === 0) {
      return null;
    }
    const currentSelectedState = this.getCurrentLocalState();

    const subMenuWithStates = states.map((state) => {

      let payload = {
        id: state.id,
        content: state.name,
        tooltipText: state.description,
        className: currentSelectedState?.id === state.id ? 'selected' : '',
        onClickFunction: (event: any) => {
          this.selectState(state);
        },
      };
      if (state.id === currentSelectedState?.id) {
        payload = {
          ...payload,
          //@ts-ignore
          image: {src: 'assets/mat-symbols/outlined/check.svg', width: 12, height: 12, x: 5, y: 6},
        }
      }
      return payload;
    });
    const baseStateMenuItem = {
      id: 'base_state',
      content: 'Base',
      // tooltipText: 'Base State',
      onClickFunction: (event: any) => {
        this.selectState(null);
      },
      hasTrailingDivider: true,
    }

    return {
      id: 'states_menu',
      content: 'Show State',
      // tooltipText: 'Show State',
      image: {src: 'assets/mat-symbols/outlined/deployed_code.svg', width: 12, height: 12, x: 5, y: 6},
      coreAsWell: true,
      submenu: [baseStateMenuItem,...subMenuWithStates],
    };
  }
}
