import { Injectable, signal } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { SavedView } from '../interfaces/saved-view';
import { ViewResource } from '../resource/view.resource';
import { CytoscapeService } from '../../services/cy-services/cytoscape.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog.component';
import { TourResource } from '../resource/tour.resource';
import { SettingsService } from './setting.service';
import { SidebarState } from '../../../../../../libs/shared/src/lib/consts/sidebar.state';
import { TourStep } from '../interfaces/map-tour';
import { MapService } from './map.service';
import { PackageService } from './package.service';
import { MODULE_NAMES } from '@maporium-workspace/shared';
import { NodeService } from './node.service';
import { MapStateService } from './map-state.service';
import { invalidatePanZoomForCollapsedNode } from '../../../cytoscape-extensions/pan-zoom-storage';
import { isInIframe } from '../../shared/helpers/maporium.validators';
import { DashboardComponent } from '../../dashboard/dashboard.component';

@Injectable({providedIn: 'root'})
export class ViewService {
  public viewChangeInProgress = false;
  public viewHasLoaded = new Subject<boolean>();
  private currentViewsSignal = signal<SavedView[]>([]);

  constructor(private resource: ViewResource,
              private mapService: MapService,
              private mapStateService: MapStateService,
              private tourResource: TourResource,
              private packageService: PackageService,
              private nodeService: NodeService,
              private stateService: MapStateService,
              private settingsService: SettingsService,
              private matDialog: MatDialog,
              private cyService: CytoscapeService) {
  }

  get currentViews(): SavedView[] {
    return this.currentViewsSignal();
  }

  public getAll(mapId: string): Observable<SavedView[]> {
    return this.resource.getAll(mapId)
      .pipe(
        tap((views) => {
          this.currentViewsSignal.set(views);
        }));
  }

  public getAllPublic(mapId: string): Observable<SavedView[]> {
    return this.resource.getAllPublic(mapId);
  }

  public create(view: SavedView): Observable<SavedView> {
    return this.resource.create(view);
  }

  public update(view: SavedView): Observable<SavedView> {
    return this.resource.update(view);
  }

  public delete(view: SavedView, success?: () => void): void {
    this.tourResource.getAll(view.map.id).subscribe((tours) => {
      const viewIsInTour = tours.some((tour) => {
        return tour.steps.some((step) => {
          return step.savedView.id === view.id;
        });
      });
      const toursWithView = tours.filter((tour) => {
        return tour.steps.some((step) => {
          return step.savedView.id === view.id;
        });
      });
      const tourNames = toursWithView.map((tour) => {
        return tour.name;
      });
      if (viewIsInTour) {
        const ref = this.matDialog.open(ConfirmationDialogComponent, {
          data: {
            title: 'Confirm Deletion',
            message: `This scene is used in the following stories and will be removed from them:
            '${tourNames.join('\', \'')}' \n \n Are you still sure that you want to delete the '${view.name}' scene?`
          }
        });
        ref.afterClosed().subscribe((result) => {
          if (result) {
            this.resource.delete(view).subscribe(() => {
              if (success) {
                success();
              }
            });
          }
        });
      } else {
        this.resource.delete(view).subscribe(() => {
          if (success) {
            success();
          }
        });
      }
    });
  }

  public async getCtxMenuItems() {
    const mapId = this.mapService.getCurrentSelectedMapFromStore().id;
    if (!mapId) {
      return null;
    }

    if (!this.packageService.hasModule(MODULE_NAMES.VIEW_USAGE)) {
      return null;
    }


    const views = isInIframe() ? await this.getAllPublic(mapId).toPromise() : await this.getAll(mapId).toPromise();

    if (!views || views.length === 0) {
      return null;
    }

    const subMenuWithViews = views.map((view) => {
      return {
        id: view.id,
        content: view.name,
        tooltipText: view.description,
        onClickFunction: (event: any) => {
          this.playView(view);
        },
      };
    });
    return {
      id: 'views_menu',
      content: 'View Scene',
      // tooltipText: 'View Scene',
      image: {src: 'assets/mat-symbols/outlined/location_on.svg', width: 12, height: 12, x: 5, y: 6},
      coreAsWell: true,
      submenu: subMenuWithViews
    };
  }

  public async playView(view: SavedView, step?: TourStep, end?: () => void) {
    invalidatePanZoomForCollapsedNode();
    const cy = this.cyService.cy;
    if (!cy) {
      return;
    }
    const selectedElementIdsBefore = cy.elements(':selected').map((element) => element.id());

    this.viewChangeInProgress = true;
    // @ts-ignore
    const expandCollapse = cy.expandCollapse('get');
    const collapsedNodes = view.collapsedNodes;
    const selectedElementIds = view.selected;
    const currentSelectedState = this.stateService.getCurrentLocalState();
    const timeout = 1000;

    const toggleSidebar = () => {
      if (view.sidebarState !== null && view.sidebarState === SidebarState.OPEN) {
        this.settingsService.storeSidebarWidth(view.metadata?.sidebarWidth || 300);
        this.settingsService.toggleSidebar({forceOpen: true});
      } else if (view.sidebarState !== null && view.sidebarState === SidebarState.CLOSED) {
        this.settingsService.toggleSidebar({forceClose: true});
      }
    };

    const moveViewIntoScene = async (shouldAnimate = true, end?: () => void) => {
      if (shouldAnimate) {
        cy?.animate({
          pan: view.pan,
          zoom: view.zoom,
          duration: step ? step.animationSpeed : 1000, // Animation duration in milliseconds
          easing: step ? step.animationCurve : 'ease-in-out', // Animation easing function
          complete() {
            toggleSidebar();
            if (end) {
              end();
            }
          }
        });
      } else {
        cy?.pan(view.pan);
        cy?.zoom(view.zoom);
        toggleSidebar();
        if (view.includeCurrentState) {
          await selectSelected();
        }
      }

    };

    const applyExpandCollapse = () => {
      cy?.nodes().forEach((node) => {
        if (collapsedNodes.includes(node.id())) {
          expandCollapse.collapse(node);
          node.addClass('collapsed');
          node.data('collapsed', true);
          if (node.data('collapsedChildren')) {
            const payload = {...node.data()};
            delete payload.collapsedChildren;
            delete payload.links;
            //@ts-ignore
            this.nodeService.updateNode(payload).subscribe();
          }
        } else {
          node.removeClass('collapsed');
          expandCollapse.expand(node);
          node.data('collapsed', false);
          if (node.data('children')) {
            const payload = {...node.data()};
            delete payload.links;
            //@ts-ignore
            this.nodeService.updateNode(payload).subscribe();
          }
        }
      });
    };

    const selectSelected = async () => {
      this.cyService.resetAllSelected();
      if (selectedElementIds !== null) {
        if (selectedElementIds.length) {
          cy?.elements().deselect();
          cy?.elements()
            .filter((element) => {
              if (selectedElementIds.includes(element.id())) {
                element.data('savedViewSelected', true);
                return true;
              }
              return false;
            }).select();

          if (view.metadata?.focusedElementId) {
            const focusedElement: any = cy?.elements(`#${view.metadata.focusedElementId}`);
            const getElementDataBasedOnState = (element: any) => {
              console.log(element);
              const stateId = view.savedStateId;
              if (stateId) {
                const elementData = element.data();
                const stateData = elementData.states.find((state: any) => state.stateId === stateId);
                if (stateData.node) {
                  return stateData.node;
                } else if (stateData.link) {
                  return stateData.link;
                }
                return null;
              } else {
                return element.data();
              }
            };

            if (!focusedElement) {
              return;
            }
            if (focusedElement.isNode()) {
              DashboardComponent.tappedNode = getElementDataBasedOnState(focusedElement);
            } else {
              DashboardComponent.tappedLink = getElementDataBasedOnState(focusedElement);
            }
          }
        } else {
          this.cyService.resetAllSelected();
          cy?.nodes().deselect();
          return;
        }
      } else {
        if (selectedElementIdsBefore.length > 0) {
          cy?.elements(selectedElementIdsBefore.map(id => `#${id}`).join(', ')).select();
        }
      }
    };

    if (!view.includeCurrentState) {
      await selectSelected();
      applyExpandCollapse();
      await moveViewIntoScene();
    }

    this.viewHasLoaded.subscribe((value) => {
      if (value) {
        this.viewChangeInProgress = false;
      }
    });

    if (view.includeCurrentState) {
      if (view.savedStateId) {
        if (currentSelectedState?.id !== view.savedStateId) {
          this.stateService.findOne(view.savedStateId as string)
            .subscribe(async (state) => {
              this.mapStateService.setSceneChangeProgress(true);
              this.stateService.selectState(state);
              //TODO: Rework with signals
              setTimeout(async () => {
                await selectSelected();
                await moveViewIntoScene(true);
                this.mapStateService.setSceneChangeProgress(false);
              }, timeout)
            });
        } else {
          this.stateService.selectState(currentSelectedState);
          setTimeout(async () => {
            await selectSelected();
            await moveViewIntoScene(true);
          }, timeout);
        }
      } else {
        // Working with base state view
        if (currentSelectedState?.id !== undefined && view.savedStateId !== undefined) {
          this.stateService.selectState(null);
          setTimeout(async () => {
            await selectSelected();
            await moveViewIntoScene(true);
          }, timeout);
        } else {
          await selectSelected();
          await moveViewIntoScene(true);
        }

      }
    }

    if (view.linkState !== null) {
      this.settingsService.setLinkViewMode(view.linkState);
    }
  }
}
