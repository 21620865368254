<div class="title-description-form">
  @if (isEditing) {
    <form [formGroup]="formGroup">
      <div class="static-label-input write-title">
        <textarea type="text"
                  formControlName="title"
                  class="no-border-input w-100 name-input"
                  matInput
                  #titleInput
                  appAutoResizeInput
                  cdkTextareaAutosize
                  [class.no-border-input]="titleInput !== document.activeElement"
                  (keydown.enter)="onEnter($event)"
                  [defaultHeight]="'33px'"
                  id="titleInput"
                  placeholder="Name"
                  autoSelect></textarea>
      </div>
      <div class="floating-label-textarea">
        <textarea id="descriptionField"
                  class="no-border-input w-100 description-input"
                  #textAreaElement
                  cdkTextareaAutosize
                  formControlName="description"
                  [class.no-border-input]="textAreaElement !== document.activeElement"
                  placeholder="Description"></textarea>
      </div>
    </form>
  } @else {
    @if (fields?.title) {
      <div class="form-group read-title-wrapper">
        <span class="read-title">{{ fields!.title }}</span>
      </div>
    }
    @if (fields?.description) {
      <div class="form-group">
        @if (isMarkdownEnabled) {
          <span class="read-description markdown" [innerHTML]="description"></span>
        } @else {
          <span class="read-description">{{ fields!.description }}</span>
        }
      </div>
    }
  }
</div>
